
import './App.css';
import Main from '../src/Components/Main';
// import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
   <div>
   <Main></Main>
    </div>
  );
}

export default App;
